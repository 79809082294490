import React from "react";
import PortableText from "./portableText";
import Author from "./Author";
import Img from "gatsby-image";
import styled from "styled-components";
import SocialShare from "../components/SocialShare";
import TagCloud from "../components/TagCloud";
import { PageDivider } from "../components/ui-components";

const ArticleWrapper = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0.9rem;

  @media only screen and (max-width: 449px) {
    padding: 0 0.65rem;
  }


  figcaption {
    margin-top: 10px;
    font-family: "Source Sans Pro", sans-serif;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 15px;
    @media only screen and (max-width: 480px) {
      font-size: 14px;
    }
  }
`;

const ContentWrapper = styled.article`
  color: rgba(0, 0, 0, 0.8);
  font-family: Merriweather, serif;

  p,
  li {
    letter-spacing: 0.35px;
    line-height: 1.85rem;
  }

  blockquote {
    color: rgba(0, 0, 0, 0.55);
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 1.25rem 0.5rem;
    font-style: italic;
  }

  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    border-bottom: 2px solid rgb(73, 126, 107);
    padding: 1px 2px 0;
    -webkit-transition: background-color 0.2s linear, color 0.2s linear, border 0.2s linear;
    transition: background-color 0.2s linear, color 0.2s linear, border 0.2s linear;
    color: inherit;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      outline-width: 0;
      color: #000;
      background-color: rgba(73, 126, 107, 0.5);
      border-bottom: 2px solid rgba(73, 126, 107, 0.5) !important;
    }
  }
`;

const PostTitle = styled.h1`
  margin: 2rem 0 1.25rem;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
  font-family: Merriweather, serif;
  font-size: 2.25rem;
  line-height: 1.25;

  @media only screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

const AuthorSocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 480px) {
    flex-direction: column-reverse;
  }
`;

function BlogPost(props) {
  const {
    _rawBody,
    authors,
    title,
    mainImage,
    hideMainImageOnPage,
    publishedAt,
    postUrl,
    tags,
  } = props;

  const formattedAuthors = authors.map(({ author }) => {
    return <Author author={author} publishedAt={publishedAt} key={author.id} />;
  });

  return (
    <ArticleWrapper>
      <PostTitle>{title}</PostTitle>

      <AuthorSocialWrapper>
        {authors ? <div>{formattedAuthors}</div> : null}
        <SocialShare postUrl={postUrl} />
      </AuthorSocialWrapper>

      {mainImage && !hideMainImageOnPage ? (
        <figure>
          {mainImage.asset ? (
            <div style={{ marginTop: "1.75rem" }}>
              <Img fluid={mainImage.asset.fluid} alt={mainImage.alt || ""} />
            </div>
          ) : null}
          {mainImage.caption ? <figcaption>{mainImage.caption}</figcaption> : null}
        </figure>
      ) : (
        <div style={{ marginTop: "1.75rem" }}></div>
      )}

      <ContentWrapper>{_rawBody && <PortableText blocks={_rawBody} />}</ContentWrapper>

      {tags.length > 0 ? <TagCloud tags={tags} /> : null}

      <PageDivider />

      {authors ? (
        <div style={{ marginBottom: "1.25rem" }}>
          <p
            style={{
              textTransform: "uppercase",
              marginBottom: "0.25rem",
              color: "rgba(0,0,0,0.54)",
              fontSize: "0.85rem",
            }}
          >
            Written By:
          </p>
          {formattedAuthors}
        </div>
      ) : null}

      <div style={{ marginBottom: "1.25rem" }}>
        <SocialShare postUrl={postUrl} />
      </div>
    </ArticleWrapper>
  );
}

export default BlogPost;
