import React from "react";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import styled from "styled-components";

const ShareWrapper = styled.div`
  display: flex;

  @media only screen and (min-width: 481px) {
    align-self: flex-end;
  }

  @media only screen and (max-width: 480px) {
    margin-bottom: 1.25rem;
  }

  .react-share__ShareButton {
    padding: 0.25rem !important;
    margin: 0 0.25rem;
    height: 2rem;

    svg {
      height: 22px;
      fill: #757575;
      transition: fill 0.3s;
      transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    }

    &:hover,
    &:active {
      svg {
        fill: #000000cc;
      }
    }
  }
`;

export default function SocialShare({ postUrl }) {
  return (
    <ShareWrapper>
      <TwitterShareButton url={postUrl}>
        <svg viewBox="0 0 512 512">
          <path d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z" />
        </svg>
      </TwitterShareButton>
      <br />
      {/* <LinkedinShareButton url={postUrl}>
        <svg viewBox="0 0 512 512">
          <path d="M475.074 0H36.926C16.53 0 0 16.53 0 36.926v438.148C0 495.47 16.53 512 36.926 512h438.148C495.47 512 512 495.47 512 475.074V36.926C512 16.53 495.47 0 475.074 0zM181.61 387h-62.348V199.426h62.348zm-31.172-213.187h-.406c-20.922 0-34.453-14.402-34.453-32.402 0-18.406 13.945-32.4 35.273-32.4s34.453 14.004 34.86 32.4c0 18-13.53 32.402-35.273 32.402zM406.422 387h-62.34V286.652c0-25.22-9.027-42.418-31.586-42.418-17.223 0-27.48 11.602-31.988 22.8-1.648 4.008-2.05 9.61-2.05 15.215V387h-62.344V199.426h62.344v26.56c8.285-12.78 23.11-30.96 56.188-30.96 41.02 0 71.777 26.81 71.777 84.422zm0 0" />
        </svg>
      </LinkedinShareButton>
      <br /> */}
      <FacebookShareButton url={postUrl}>
        <svg viewBox="0 0 512 512">
          <path d="M297.277 508.668l-6.422.96 6.422-.96zm5.12-.875l-3.06.535 3.06-.535zm-17.26 2.547l-7.527.742 7.527-.742zm4.918-.602l-3.6.45 3.6-.45zm19.312-3.338l-2.703.566 2.703-.566zm17.297-4.287l-2.18.605 2.18-.605zm-5.23 1.43l-2.375.617 2.375-.617zm-6.834 1.71l-2.523.566 2.523-.566zm-37.073 5.846l-4.047.305 4.047-.305zM512 256C512 114.637 397.363 0 256 0S0 114.637 0 256s114.637 256 256 256l4.5-.06V312.656h-55V248.56h55V201.4c0-54.703 33.395-84.477 82.2-84.477 23.367 0 43.453 1.742 49.3 2.52v57.172h-33.648c-26.547 0-31.687 12.617-31.687 31.13v40.824h63.477l-8.273 64.098h-55.203V502.1C433.668 471.434 512 372.852 512 256zM272.914 511.43l-8.023.398 8.023-.398zm-8.16.406l-4.25.105 4.25-.105zm0 0" />
        </svg>
      </FacebookShareButton>
    </ShareWrapper>
  );
}
