import React from "react";
import { graphql } from "gatsby";
import BlogPost from "../components/blog-post";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt(formatString: "MMM DD, YYYY")
      mainImage {
        alt
        caption
        asset {
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid
          }
          path
        }
      }
      hideMainImageOnPage
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      authors {
        _key
        author {
          id
          name
          image {
            asset {
              fixed(width: 48) {
                ...GatsbySanityImageFixed_noBase64
              }
            }
          }
          slug {
            current
          }
        }
      }
      tags {
        id
        slug {
          current
        }
        tagName
      }
    }
  }
`;

const BlogPostTemplate = (props) => {
  const { data, location } = props;
  const post = data && data.post;
  const siteUrl = data && data.site.siteMetadata.siteUrl;
  const postUrl = `${siteUrl}/${post.slug.current}/`;

  return (
    <Layout location={location.pathname}>
      {post && (
        <SEO
          title={post.title || "Untitled"}
          description={toPlainText(post._rawExcerpt)}
          image={post.mainImage}
        />
      )}
      {post && <BlogPost {...post} postUrl={postUrl} />}
    </Layout>
  );
};

export default BlogPostTemplate;
